.new-home-sec {
    padding: 48px;
    padding-bottom: 4em;
    padding-left: 4em;
    padding-right: 4em;
}

.new-home-box {
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
    width: 100%;
}

.new-home-sidebar {
    width: 20%;
    /* padding: 1em; */
    position: sticky;
    align-self: flex-start;
    top: 50px;
    overflow-y: auto;
}

.new-home-main-wrapper {
    width: 80%;
    /* background-color: #f9fafc; */
    padding: 2em;
}

.profile-logo-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 2em 0; */
}

.sibebar-header-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 2em;
    padding-top: 1em;
}

.sidebar-user-img-sec {
    background-color: #000000;
    border: 4px solid var(--primary-color);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

.sidebar-user-no-fea-img-sec{
    background-color: #000000;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

@keyframes blink {
    50% {
        border-color: #fff;
    }
}

.sidebar-user-img {
    /* height: 7em;
    width: 7em; */
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.sibebar-header-sec h4 {
    font-size: 2em;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0;
    color: var(--tertiary-color);
    padding-top: 1em;
}

.dot-circle {
    width: 1.5em;
    height: 1.5em;
    background: #2bd618;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 2px;
}

.sidebar-live-btn {
    position: absolute;
    bottom: -16px;
    color: #fff;
    background: linear-gradient(90deg, var(--primary-color) 4.55%, #AAA7F5 47.39%, var(--primary-color) 92.42%);
    border: 3px solid #FFFFFF;
    font-size: 1.4em;
    padding: 2px 15px;
    font-weight: 600;
    border-radius: 5px;
}

.sidebar-user-name {
    color: #fca4bc;
}

.sidebar-user-name:hover {
    color: #fca4bc;
    font-size: 1.6em;
    font-weight: 500;
}

.sidebar-total-count-info-box {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    /* gap: 1rem; */
    place-items: center;
    text-align: center;
    width: 100%;
}

.sidebar-total-count-card {
    position: relative;
}

.sidebar-total-count-card::before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    top: 0;
    right: -18px;
}

.sidebar-total-count-card:last-child::before {
    display: none;
}

.sidebar-total-count-card h5 {
    font-size: 1.3em;
    font-weight: 700;
}

.sidebar-total-count-card p {
    color: #fca4bc;
    margin-bottom: 0;
    font-size: 1.1em;
    font-weight: 500;
}

.sidebar-links {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2.5em 1.25em;
}

.sidebar-links ul li a {
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 1.5em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.sidebar-links ul li {
    margin-top: 0;
    padding-bottom: 2em;
}

.sidebar-links ul li:last-child {
    padding-bottom: 0;
}

.sidebar-social-links {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2.5em 0;
}

.sidebar-social-links ul li {
    margin-top: 0;
}

.sidebar-social-links ul {
    grid-template-columns: repeat(7, 1fr);
    display: grid;
    gap: 1rem;
}

.user-cover-img-sec .user-cover-img {
    width: 100%;
    height: 28em;
    object-fit: cover;
    border-radius: 20px;
}

.user-info-list ul {
    margin-bottom: 0;
}

.user-info-list ul li {
    background: #F2F2F7;
    border-radius: 50px;
    padding: 8px 15px;
    margin-top: 0;
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 1em;
}

.user-right-content-sec {
    grid-template-columns: 700px auto;
    display: grid;
    gap: 1rem;
}

/* .user-info-list ul {
    display: flex;
    align-items: center;
} */

.user-info-list ul li a {
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: default;
}

.user-info-list ul li a span {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 500;
}

.user-right-info {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2em 0;
}

.user-info-desc {
    padding-bottom: 2em;
}

.user-info-desc p {
    color: #fca4bc;
    margin-bottom: 0;
    /* font-size: 1.3em; */
    font-size: 1.7em;
    /* font-weight: 400; */
    font-weight: 500;
    padding-right: 1em;
}

.user-info-desc p a {
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 400;
}

.user-subscription-plans-details {
    padding: 15px;
}

.user-subscription-plans-details h3 {
    font-size: 1.6em;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 1em;
}
.user-subscription-plans-details p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.user-subscription-plans-details span {
    font-size: 12px;
}

.user-subscription-plans-details-start {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #6561e333;
    margin-top: 25px;
}

.user-subscription-plans-details-start span {
    font-size: 12px;
    font-weight: 500;
}

.user-subscription-plans-details-end {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.user-subscription-plans-details-end span {
    font-size: 12px;
    font-weight: 500;
}

.user-subscription-btn-sec {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5em;
    padding: 2em 0;
}

.user-subscription-des {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.user-subscription-des span {
    font-size: 1.3rem;
}

.subscription-outline-btn {
    border: 1px solid var(--button-hover-color)!important;
    padding: 8px 30px;
    font-size: 1.4em;
    color: #222222;
    font-weight: 500;
    background: var(--secondary-color);
    border-radius: 5px;
    transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
}

.subscription-outline-btn:hover {
    background: var(--button-hover-color);
    color: var(--secondary-color) !important;
}

.subscription-btn {
    border: 1px solid var(--button-hover-color)!important;
    padding: 8px 30px;
    font-size: 1.4em;
    color: var(--secondary-color);
    font-weight: 500;
    background: var(--button-hover-color)!important;
    border-radius: 20px;
    transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
}

.subscription-btn1 {
    border: 1px solid var(--button-hover-color)!important;
    padding: 8px 30px;
    font-size: 1.4em;
    color: var(--secondary-color);
    font-weight: 500;
    background: var(--button-hover-color)!important;
    border-radius: 5px;
    transition: opacity .15s ease, background-color .15s ease, box-shadow .15s ease;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
}

.subscription-btn1 span {
    font-size: 1.4rem;
}

.subscription-btn1:hover {
    color: #ffffff !important;
}

.subscription-btn:hover {
    color: var(--secondary-color) !important;
}

.profile-tab-sec {
    padding: 2em 0;
}

.profile-tab-sec .nav {
    display: grid;
    gap: 0;
    border-bottom: 1px solid rgba(101, 97, 227, 0.2);
}

.grid-four-col {
    grid-template-columns: repeat(4, 1fr);
}

.grid-five-col {
    grid-template-columns: repeat(5, 1fr);
}

.profile-tab-sec .nav::before {
    display: none;
}

.profile-post-tab-icon {
    max-width: 1.5em;
    object-fit: contain;
}

.profile-tab-sec .nav-pills .nav-link.active {
    background-color: transparent;
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 0;
}

.profile-tab-sec .nav-pills .nav-link {
    font-size: 1.6em;
    font-weight: 600;
    padding: 0.5em 2em;
    display: flex;
    align-items: center;
    gap: 1em;
    color: #fca4bc;
}

.profile-all-post-box {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 2em;
    margin-top: 3em;
}

/* Post Audio CSS*/

.profile-audio-img {
    height: 14em;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.profile-audio-post-card {
    position: relative;
}

.profile-audio-post-card::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 0.5em;
}

.profile-audio-icon-sec {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-audio-icon {
    max-width: 2.5em;
}

.profile-audio-post-box {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 2em;
    margin-top: 3em;
}

/*Post Lock CSS*/

.profile-lock-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.profile-lock-post-card {
    position: relative;
    height: 100%;
}

.profile-lock-post-card::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 0.5em;
}

.profile-lock-img-sec {
    height: 100%;
}

.user-profile1 {
    height: 100%;
    border-radius: 10px;
}

.profile-lock-icon-sec {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}



.new-single-page-box .profile-lock-icon{
    max-width: 4em;
    z-index: 999;
}

/*Profile Video CSS*/

.profile-video-img {
    height: 14em;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.profile-video-post-card {
    position: relative;
}

.profile-video-img-sec>.player-buttons{
    background: url('/assets/images/icons/play-button.svg') center center no-repeat;
    height: 128px;
    left: 50%;
    margin: -64px 0 0 -64px;
    position: absolute;
    top: 50%;
    width: 128px;
    z-index: 1; 
    transform: scale(0.5);
}

.profile-video-img-sec>.player-buttons:hover{
    background: url('/assets/images/icons/play-button-grey.svg') center center no-repeat;
    height: 128px;
    left: 50%;
    margin: -64px 0 0 -64px;
    position: absolute;
    top: 50%;
    width: 128px;
    z-index: 1; 
    transform: scale(0.5);
}

.profile-video-post-card::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 0.5em;
}

.profile-video-icon-sec {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    z-index: 999;
    cursor: pointer;
}

.profile-video-icon {
    z-index: 999;
    /* max-width: 2.5em; */
}

.profile-video-post-box {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 2em;
    margin-top: 3em;
}

/* Post Image CSS*/

.profile-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.profile-image-post-card {
    position: relative;
    height: 100%;
}

.profile-image-img-sec {
    height: 100%;
}

.profile-img-post-box {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 2em;
    margin-top: 3em;
}

.mobile-display {
    display: none;
}

.desktop-display {
    display: block;
}

.new-settings-sidebar-link-list {
    margin-bottom: 0;
}

@media (max-width: 991.98px) {
    .desktop-display {
        display: none;
    }
}

.profile-post-card-loader {
    height: 224px;
    border-radius: 10px !important;
}

@media (max-width: 767.98px) {
    .profile-post-card-loader {
        height: 176px !important;
    }
}

.new-profile-store-btn-sec select, .new-profile-store-btn-sec .dropdown button{
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-size: 14px;
    transition: all 150ms ease;
    width: 170px;
    height: 52px;
    box-sizing: border-box;
    background: linear-gradient(#f5f5ff,#fff);
    border: 0;
    border: 1px solid var(--primary-color);
    border-radius: 50px;
    padding: 10px 12px;
    color: #6c757d;
}

.new-profile-store-btn-sec select:focus, .new-profile-store-btn-sec .dropdown button:focus{
    outline: none;
}

.new-profile-store-header-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4em;
    padding-left: 2em;
    padding-right: 2em;
    width: 100%;
}
.new-profile-store-btn-sec{
    grid-template-columns: 170px auto;
    display: grid;
    gap: 1rem;
}

.new-explore-search-card .new-explore-search-icon{
   max-width: 1.5em;
}

.sidebar-total-count-media-card::before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    top: 0;
    right: -18px;
}

.profile-tab-counter {
    display: flex;
    gap: 4px;
}
.profile-user-cover-img {
    width: 100%;
    height: 18em;
    object-fit: cover;
}
.user-avatar {
    border-radius: 50%;
    border: 2px solid #fff;
    width: 100px;
    height: 100px;
}
.user-avatar-container {
    border-radius: 50%;
    margin-top: -35px;
    margin-left: 15px;
    z-index: 1;
    position: relative;
}
.profile-intro {
    background-color: var(--light-dark-color);
    /* margin-left: -15px;
    margin-right: -15px; */
}
.profile-subscription {
    background-color: var(--light-dark-color);
}
.user-avatar-container .dot-circle-online {
    width: 1.5em;
    height: 1.5em;
    background: #2bd618;
    border-radius: 50%;
    position: absolute;
    bottom: 7px;
    left: 70px;
}
.profile-description {
    padding: 15px;
}
.profile-description-content > p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto,sans-serif;
}
.profile-user-cover-header {
    position: absolute;
    display: flex;
    align-items: center;
    top: 10px;
    color: white;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    z-index: 2;
}
.profile-user-cover-header-left {
    display: flex;
    gap: 10px;
}
.profile-user-cover-header-title-info {
    display: flex;
    gap: 5px;
}
.profile-user-cover-header-title-info span {
    font-weight: 500;
    font-size: 12px;
}
.feed-post-dropdown.show > .btn-success.dropdown-toggle {
    background: none !important;
}
.profile-buttons {
    display: flex !important;
    flex-direction: column;
    padding: 15px;
    margin-top: 10px;
}
.profile-buttons .sidebar-links {
    padding-top: 0;
}
.profile-buttons .sidebar-links ul {
    grid-template-columns: repeat(2, 1fr) !important;
    display: grid !important;
    gap: 2em;
}
.profile-buttons .sidebar-links ul li {
    padding-bottom: 0 !important;
}
.profile-buttons .sidebar-social-links ul {
    display: flex !important;
    align-items: center !important;
    justify-content: space-evenly !important;
}
.user-cover-img-sec:before {
    content: "";
    background: linear-gradient(rgba(138,150,163,0),#000);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    pointer-events: none;
    opacity: .25;
    z-index: 1;
}
.user-cover-img-sec:after {
    content: "";
    background: linear-gradient(#000,rgba(138,150,163,0));
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 144px;
    pointer-events: none;
    opacity: .4;
}

.profile-social-links ul {
    margin-bottom: 0;
    margin-top: 15px;
}

.profile-social-links ul li {
    background: #F2F2F7;
    border-radius: 50px;
    padding: 4px 8px;
    margin-top: 0;
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 1em;
}

.profile-social-links ul li a {
    display: flex;
    align-items: center;
    gap: 0.3em;
    cursor: pointer;
}

.profile-social-links ul li a span {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 500;
}
