.check-preview {
    display: flex;
    margin-top: 2rem;
}
.check-preview-box {
    margin: 0 !important;
}
.check-preview-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    font-size: small;
}